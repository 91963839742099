<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="gradient-y-overlay-lg-white position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/img/events/apiecobooster/images/apiecobooster.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-lg-3 space-top-2 pl-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-md-3 mb-0">
            <li class="breadcrumb-item">
              <!-- <a href="/events"> -->
              <router-link :to="{ path: '/events' }">
                Events
              </router-link>
              <!-- </a> -->
            </li>
            <li class="breadcrumb-item active" aria-current="page">API EcoBooster</li>
          </ol>
        </nav>
      </div>
      <!-- Title  -->
      <div class="container space-top-1 space-bottom-2 position-relative">
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Embrace New Technology with Open APIs in SMART BANKING</span>
            <h1>API EcoBooster</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <OrganiserBar :list="organiser_list" />
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pl-lg-0">
          <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apiecobooster/images/apiecobooster.jpg') + ')'}" />
        </div>

        <div class="col-lg-6 order-lg-1">
          <div class="pt-8 pt-lg-0">
            <div class="mb-5 mb-md-7">
              <span class="d-block small font-weight-bold text-cap mb-2">About the Event</span>
              <h2 class="mb-3">What's API EcoBooster</h2>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>To boost the open Application Programming Interface (API) Economy for different industries</p>
                </div>
              </div>

              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>To transform valuable business assets to delightful customer experience and operational efficiency through innovative applications</p>
                </div>
              </div>
            </div>

            <span class="d-block text-center">
              <a class="btn btn-primary transition-3d-hover" href="https://gaa.info.hkstp.org/en/challenges/api-ecobooster-2020" target="_blank">
                Learn More
              </a>
            </span>
          </div>
        </div>
      </div>

      <!-- More features -->
      <div class="mt-11">
        <ul class="step step-md step-centered">
          <li v-for="item in feature_list" :key="item.content" class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i :class="item.icon" /></span>
              <div class="step-content">
                <p>{{ item.content }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== How it works Section ========== -->
    <div v-for="section in section_list" :key="section.title" class="bg-light space-2">
      <div class="container">
        <div class="row justify-content-lg-between">
          <div class="col-lg-5 pl-lg-0" :class="section.image_order">
            <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apiecobooster/images/' + section.image_url) + ')'}" />
          </div>

          <div class="col-lg-6" :class="section.content_order">
            <div class="pt-8 pt-lg-0">
              <div class="mb-5 mb-md-7">
                <span class="d-block small font-weight-bold text-cap mb-2">{{ section.title }}</span>
                <h2 class="mb-3">{{ section.header }}</h2>
                <p class="mb-5">{{ section.discription }}</p>

                <div v-for="item in section.list" :key="item" class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    <p>{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End How it works Section ========== -->

    <!-- ========== Application Steps Section ========== -->
    <div class="container space-2">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Important dates</span>
        <h2>Programme Timeline</h2>
      </div>

      <ul class="step step-md step-centered">
        <li v-for="(item, index) in timeline_list" :key="item.date" class="step-item">
          <div class="step-content-wrapper">
            <span class="step-icon step-icon-soft-primary">{{ index + 1 }}</span>
            <div class="step-content">
              <h3>{{ item.date }}</h3>
              <p>{{ item.event }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- ========== End Application Steps Section ========== -->

    <!-- ========== Subscription Section ========== -->
    <div class="container-fluid space-2 pace-bottom-lg-0 gradient-y-sm-primary">
      <div class="row justify-content-sm-center align-items-lg-center text-center">
        <div class="col-lg-3 d-none d-lg-block" />

        <div class="col-sm-8 col-lg-6">
          <div class="mb-5">
            <h2>Interested in API EcoBooster?</h2>
            <p>Subscribe to our newsletter to be the first to be notified with the latest updates.</p>
          </div>
          <!-- Subscribe Form -->
          <div class="w-md-75 w-lg-80 mx-auto form-subscribe">
            <!-- <form ref="myform" /> -->
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=00a108e7f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from beNovelty or OpenAPIHub by beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2142806,2518966"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Submit" name="subscribe" class="btn btn-primary border-0 mx-auto"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->
          </div>
          <!-- End Subscribe Form -->
        </div>

        <div class="col-lg-3 d-none d-lg-block" data-aos="fade-left">
          <div class="max-w-33rem w-100 transform-rotate-2 ml-auto">
            <div class="device device-iphone-x">
              <img class="device-iphone-x-frame" src="@/assets/svg/illustrations/subscribe-mobile.svg">
            </div>

            <figure class="max-w-19rem w-100 position-absolute top-0 left-0 z-index-n1 mt-n5 ml-n5">
              <img class="img-fluid" src="@/assets/svg/components/dots-2.svg">
            </figure>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Subscription Section ========== -->

  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'

export default {
  name: 'Apidays',
  components: {
    OrganiserBar
  },
  data() {
    return {
      feature_list: [
        {
          icon: 'fas fa-home',
          content: 'Value Business Assets'
        },
        {
          icon: 'fas fa-key',
          content: 'Open API'
        },
        {
          icon: 'fas fa-lightbulb',
          content: 'Developer'
        },
        {
          icon: 'fas fa-mobile-alt',
          content: 'Innovative Solutions'
        },
        {
          icon: 'fas fa-thumbs-up',
          content: 'Delightful Customer & Employee Experience'
        }
      ],
      organiser_list: {
        title: 'API EcoBooster',
        image_path: 'apiecobooster',
        css: '',
        organiser: [
          {
            post: 'Organiser',
            image_url: 'hkstp.png',
            data_aos_delay: 0,
            css: ''
          },
          {
            post: 'Lead Partner',
            image_url: 'hsbc.png',
            data_aos_delay: 150,
            css: 'column-divider-md column-divider-20deg px-md-3'
          },
          {
            post: 'Technology Partner',
            image_url: 'benovelty.png',
            data_aos_delay: 200,
            css: 'column-divider-md column-divider-20deg px-md-3'
          }
        ]
      },
      section_list: [
        {
          image_order: 'order-lg-1',
          image_url: 'payment-1.jpg',
          content_order: 'order-lg-2',
          title: 'How it works',
          header: 'Smart Banking',
          discription: 'Echo with the Open API Framework initiated by local regulator to extend banking footprints to broader use cases:',
          list: [
            'Loan Services',
            'Transactions and Operation',
            'Credit Card',
            'Digital Payment',
            'Customer Record'
          ]
        },
        {
          image_order: 'order-lg-2',
          image_url: 'coding-2.jpg',
          content_order: 'order-lg-1',
          title: 'Why join',
          header: 'Call For Developers',
          discription: 'For qualified developer, you will be offered:',
          list: [
            'HSBC Mentorship Sessions',
            'Technical Clinics',
            'Sandbox for Solution Testing',
            'Exposure to Users of Banking Industry'
          ]
        }
      ],
      timeline_list: [
        {
          date: '14 Aug 2020',
          event: 'Application Deadline'
        },
        {
          date: '4 Sep 2020',
          event: 'Announcement of Shortlisted Proposals'
        },
        {
          date: 'Sep–Oct 2020',
          event: 'Training and Technical Clinics'
        },
        {
          date: 'Oct 2020',
          event: 'Demonstration Day'
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })

    // this.loadScript('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //         portalId: "7091000",
    //         formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.myform.appendChild(secondScript)
    // })
  },
  // methods: {
  //   loadScript(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.myform.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'API EcoBooster | %s',
      link: [
        { rel: 'canonical', href: 'https://hub.openapihub.com/community-updates/20200814-API-EcoBooster' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'API EcoBooster | beNovelty' },
        { property: 'og:description', content: 'API EcoBooster – To boost the open Application Programming Interface (API) Economy for different industries.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/apiecobooster.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events/apiecobooster' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
